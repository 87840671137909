import { Texts } from '../../const';
import Utils from '../utils';

export default class CollagesPopup {
    constructor(items, slider, page) {
        this.items = items;
        this.widgetType = 'looks';
        if (slider) {
            this.slider = slider;
            this.slider.registerSlideCallback((c, dir) => this.onSlide(c, dir));
        }

        this.options = page._widget.pageOptions;
        if (this.options) {
            this.spa = this.options.spa;
        }

        this.sliderPos = 0;
        this.page = page;
        this.similars = {};
        this.viewedLooksIds = [];
        this.sizeYmlId = null;
        if (page)
            this.ecommerce = page.ecommerce;
    }

    getOfferPropByYmlId(ymlId, product) {
        if (product.offers_props_by_yml_id) {
            const key = Object.keys(product.offers_props_by_yml_id[ymlId])[0];
            if (key != 'product_id')
                return product.offers_props_by_yml_id[ymlId][key];
            else
                return ymlId;
        } else
            return ymlId;
    }

    updateTotalInfo(look) {
        if (this.page.getWidgetParam('add_all_items_to_cart_panel')) {
            let self = this;

            let cartItems = [];
            let selectedSizes = document.querySelectorAll('.garderobo-widget-sizes');

            let selectedItems = [];
            let selectedItemsByOfferProps = [];
            for (let i = 0; i < selectedSizes.length; i++) {
                if (selectedSizes[i].value)
                    selectedItems.push({"yml_id": selectedSizes[i].value, "price": 0});
            }

            let price = 0;
            let oldPrice = 0;

            for (let i = 0; i < selectedItems.length; i++) {
                for (let j = 0; j < look.products.length; j++) {
                    for (let k = 0; k < look.products[j].sizes.length; k++) {
                        if (selectedItems[i]["yml_id"] == look.products[j].sizes[k].yml_id) {
                            let product = look.products[j];

                            selectedItems[i]["price"] = product.price;
                            selectedItems[i]["old_price"] = product.old_price;
                            selectedItems[i]["target_id"] = product.wareId ? product.wareId : product.id;

                            cartItems.push(product);
                            selectedItemsByOfferProps.push(this.getOfferPropByYmlId(selectedItems[i]["yml_id"], product));

                            if (product.price)
                                price += product.price;
                            if (product.old_price)
                                oldPrice += product.old_price;
                            else
                                oldPrice += product.price;
                        }
                    }
                }
            }

            document.querySelector('.garderobo-widget-look__bottom-panel-items-count').innerHTML = selectedItems.length;
            let priceStr = '';
            if (oldPrice != price)
                priceStr = `<div class="garderobo-widget-popup-list-item-text-discount">${this.page.priceFormatter(oldPrice)}</div><div class="garderobo-widget-popup-list-item-text-new-price">${this.page.priceFormatter(price)}</div>`;
            else
                priceStr = `<div class="garderobo-widget-popup-list-item-text-price">${this.page.priceFormatter(price)}</div>`;

            document.querySelector('.garderobo-widget-look__bottom-panel .garderobo-widget-popup-list-item-text-prices').innerHTML = priceStr;

            let cartBtn;
            if (this.page.getWidgetParam('all_items_to_cart_pattern')) {
                cartBtn = document.querySelector('.garderobo-widget-look__bottom-panel');
            } else {
                cartBtn = document.querySelector('.garderobo-widget-look__bottom-panel .garderobo-widget-popup-list-item-text-cart-btn');
            }

            if (cartBtn) {
                // cartBtn.disabled = !selectedItems.length;

                let newBtn = cartBtn.cloneNode(true);
                cartBtn.parentNode.replaceChild(newBtn, cartBtn);

                newBtn.addEventListener('click', function () {
                    if (!selectedItems.length) {
                        alert(self.page.getText(Texts.PLEASE_SELECT_SIZE_ALL));
                        return false;
                    }

                    for (let i = 0; i < selectedItems.length; i++) {
                        self.page._widget.analytics.sendEventAddToCartFromPopup(selectedItems[i]["target_id"], self.widgetType, self.page._widget.getPageSource(), selectedItems[i]["price"], selectedItems[i]["old_price"], look.look_id, self.page.response);
                    }

                    self.page._widget._callbacks.addToCartEvent.apply(null, [selectedItemsByOfferProps, cartItems]);
                });

                if (!selectedItems.length)
                    newBtn.classList.add('garderobo-widget-look__bottom-panel-disabled');
            }


            if (this.page.getWidgetParam('all_items_to_cart_pattern')) {
                let lookBottomPanelInfo = document.querySelector('.garderobo-widget-look__bottom-panel-info');
                let itemsTypo = '';
                if (selectedItems.length === 1) {
                    itemsTypo = '';
                } else if (selectedItems.length > 1 && selectedItems.length < 5) {
                    itemsTypo = 'а';
                } else {
                    itemsTypo = 'ов';
                }

                for (let node of lookBottomPanelInfo.childNodes) {
                    if (node.nodeType === Node.TEXT_NODE && node.textContent.includes('товар')) {
                        node.textContent = node.textContent.replace(/товар(а|ов)?/, 'товар' + itemsTypo);
                        break;
                    }
                }
            }
        }
    }

    drawLooksPopup(items) {
        let self = this;

        const underlay = document.createElement('div');
        underlay.classList.add('garderobo-widget-popup-container');

        const popup = document.createElement('div');
        popup.classList.add('garderobo-widget-popup');

        const closeBtn = document.createElement('button');
        closeBtn.type = 'button';
        closeBtn.classList.add('garderobo-widget-popup__btn-close');

        closeBtn.addEventListener('click', this.closePopup);
        popup.appendChild(closeBtn);

        const content = document.createElement('div');
        content.classList.add('garderobo-widget-popup-content');
        popup.appendChild(content);

        const collageContainer = document.createElement('div');
        collageContainer.classList.add('garderobo-widget-popup-collage-container');
        if (items) {
            collageContainer.appendChild(items);
        }
        content.appendChild(collageContainer);

        const listContainer = document.createElement('div');
        listContainer.classList.add('garderobo-widget-popup-list-container');
        content.appendChild(listContainer);

        const listHeader = document.createElement('div');
        listHeader.classList.add('garderobo-widget-popup-list-header');
        listContainer.appendChild(listHeader);

        const listContent = document.createElement('ul');
        listContent.classList.add('garderobo-widget-popup-list-content');
        listContainer.appendChild(listContent);

        const listFooter = document.createElement('a');
        listFooter.classList.add('garderobo-widget-popup-list-footer');
        listFooter.innerHTML = this.page.getText(Texts.LOOK_GOTO_LINK);
        listContainer.appendChild(listFooter);

        if (this.page.getWidgetParam('add_all_items_to_cart_panel')) {
            const lookBottomPanel = document.createElement('div');
            lookBottomPanel.classList.add('garderobo-widget-look__bottom-panel');
            listContainer.appendChild(lookBottomPanel);

            const addAllItemsToCartBtn = document.createElement('button');
            addAllItemsToCartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn');
            addAllItemsToCartBtn.innerHTML = this.page.getText(Texts.ALL_TO_CART);

            lookBottomPanel.classList.add('garderobo-widget-look__bottom-panel-disabled');
            lookBottomPanel.appendChild(addAllItemsToCartBtn);

            const allItemsInfoWrapper = document.createElement('div');
            allItemsInfoWrapper.classList.add('garderobo-widget-look__bottom-panel-info');

            if (!this.page.getWidgetParam('all_items_to_cart_pattern')) {
                lookBottomPanel.appendChild(allItemsInfoWrapper);
            } else {
                let allItemsToCartPattern = this.page.getWidgetParam('all_items_to_cart_pattern');
                let patternDiv = document.createElement('div');
                patternDiv.classList.add('garderobo-widget-look__bottom-panel-info');

                patternDiv.innerHTML = allItemsToCartPattern
                    .replace('%count%', `<span class="garderobo-widget-look__bottom-panel-items-count">0 </span>`)
                    .replace('%price%', `<div class="garderobo-widget-popup-list-item-text-prices"></div>`)
                    .replace('%oldprice%', '')

                lookBottomPanel.appendChild(patternDiv);
            }

            const allItemsInfoItemsCount = document.createElement('p');
            allItemsInfoItemsCount.innerHTML = this.page.getText(Texts.ITEMS) + ': <span class="garderobo-widget-look__bottom-panel-items-count">0</span>';
            allItemsInfoWrapper.appendChild(allItemsInfoItemsCount)

            const allItemsInfoItemsPrice = document.createElement('div');
            allItemsInfoItemsPrice.classList.add('garderobo-widget-popup-list-item-text-prices');
            allItemsInfoItemsPrice.innerHTML = '';
            allItemsInfoWrapper.appendChild(allItemsInfoItemsPrice);
        }

        underlay.appendChild(popup);

        window.addEventListener('resize', () => {
            this.setPopupHeight();
        });

        return underlay;
    }

    setPopupHeight(callback) {
        setTimeout(() => {
            if (window.screen.width >= 768) {
                const popup = document.querySelector('.garderobo-widget-popup');
                if (popup && popup.clientHeight > 0) {
                    popup.style.height = `${popup.clientHeight}px`;
                }
            }
            if (callback) {
                callback();
            }
        });
    }

    openPopup(lookId, item, widgetType) {
        if (widgetType)
            this.widgetType = widgetType;

        let self = this;
        this.page._widget.analytics.sendEventAction('open_look_popup', null, lookId, self.widgetType, this.page._widget.getPageSource(), undefined, undefined, this.page.response);
        let popupEl;
        if (this.page.containerEl)
            popupEl = document.querySelector(`.garderobo-widget-container-for-popup[data-container-id=${this.page.containerEl.getAttribute('id')}] .garderobo-widget-popup-container`);
        if (!popupEl)
            popupEl = document.querySelector(`.garderobo-widget-popup-container`);

        popupEl.setAttribute('data-scroll', document.documentElement.style.overflow);
        document.documentElement.style.overflow = 'hidden';

        if (popupEl) {
            const closeHandler = this.closePopup;
            const onClosePopup = function (e) {
                e.stopImmediatePropagation();
                if (e.target !== this) return;

                popupEl.removeEventListener('click', onClosePopup);
                closeHandler();
            };
            popupEl.addEventListener('click', onClosePopup);
            popupEl.classList.add('garderobo-widget-popup-container--opened');

            if (this.slider) {
                this.slider.reinit();

                const sliderElements = popupEl.querySelector('.garderobo-widget-feed-items').children;
                for (let j = 0; j < sliderElements.length; j++) {
                    if (sliderElements[j].getAttribute('data-look') == lookId) {
                        const posInSlider = j;
                        this.slider.slidePosition = posInSlider;
                        this.slider.setSliderPosition(posInSlider);
                    }
                }
            }

            let _look;
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].look_id === lookId) {
                    const pos = i;
                    this.sliderPos = i;
                    _look = this.items[i];
                    this.setPopupHeight(() => {
                        this.drawRightItems(_look);
                        document.querySelector('.garderobo-widget-popup-collage-container').scrollIntoView(0);
                    });
                    //this.ecommerce.impressionsPopup(_look, i);
                    //this.ecommerce.lookClick(_look, i);
                    break;
                }
            }
        }
    }

    closePopup() {
        const popupEl = document.querySelector('.garderobo-widget-popup-container--opened');
        if (popupEl) {
            popupEl.classList.remove('garderobo-widget-popup-container--opened');
        }
        let initBodyScroll = popupEl.getAttribute('data-scroll');
        if (initBodyScroll)
            document.documentElement.style.overflow = initBodyScroll;
        else
            document.documentElement.style.removeProperty('overflow');

        let customSizePanel = document.querySelector('.garderobo-set-size-panel');
        if (customSizePanel) {
            customSizePanel.classList.remove('garderobo-set-size-panel-active');
        }
    }

    drawRightItems(look) {
        let container;
        if (this.page.containerEl)
            container = document.querySelector(`.garderobo-widget-container-for-popup[data-container-id=${this.page.containerEl.getAttribute('id')}] .garderobo-widget-popup-list-content`);
        if (!container)
            container = document.querySelector('.garderobo-widget-popup-list-content');

        container.innerHTML = null;

        let lookCollage = document.querySelectorAll('.garderobo-widget-popup-content .garderobo-widget-feed-item[data-look="' + look.look_id + '"] .garderobo-widget-look__label-look-link');
        let lookContent = document.querySelectorAll('.garderobo-widget-popup-list-content')[0];
        let lookFooter = document.querySelectorAll('.garderobo-widget-popup-list-footer')[0];
        if (lookCollage.length) {
            let lookLink = lookCollage[0].innerHTML;

            lookContent.setAttribute('style', 'height: calc(100% - 118px);');
            lookFooter.style.display = 'block';
            lookFooter.href = lookLink;
        } else {
            lookContent.setAttribute('style', '');
            lookFooter.style.display = 'none';
            lookFooter.href = '';
        }


        for (let i = 0; i < look.products.length; i++) {
            this.drawRightItem(look.products[i], look.look_id, container, look.items_ids ? look.items_ids[i] : 0, false, look, i);
        }

        this.updateTotalInfo(look);

        if (!this.viewedLooksIds.includes(look.look_id)) {
            this.page._widget.analytics.sendEventViewProducts(this.widgetType, this.page._widget.getPageSource(), look.products, look.look_id, this.page.response);
            this.viewedLooksIds.push(look.look_id);
        }

        this.page._widget.analytics.sendEventView(this.widgetType, this.page._widget.getPageSource(), [look], 'view_look_in_popup', this.page.response,);
    }

    getCustomOfferProps(product, propName) {
        for (const key in product.offers_props_by_yml_id) {
            const value = product.offers_props_by_yml_id[key];
            if (value && typeof value === "object") {
                const keys = Object.keys(value);
                if (keys.length > 0) {
                    if (propName)
                        return value[propName]
                    else
                        return value[keys[0]];
                }
            }
        }
    }

    setLikedState(product, likeButton, lookId) {
        let self = this;
        if (this.page._widget.favoritesProductsIds) {
            let productFinded = false;

            if (this.page._widget.favoritesProductsIds.includes(product.group_id + '') || this.page._widget.favoritesProductsIds.includes(product.id + '')) {
                productFinded = true;
                likeButton.classList.add('garderobo-like-button-liked-state');
            } else if (product.offers_props_by_yml_id) {
                let productOfferPropsId = self.getCustomOfferProps(product, 'product_id');
                if (productOfferPropsId && this.page._widget.favoritesProductsIds.includes(productOfferPropsId + '')) {
                    likeButton.classList.add('garderobo-like-button-liked-state');
                    productFinded = true;
                }
            }

            if (!productFinded) {
                if (product.sizes && product.sizes.length) {
                    for (let i = 0; i < product.sizes.length; i++) {
                        if (this.page._widget.favoritesProductsIds.includes(product.sizes[i].yml_id + '')) {
                            likeButton.classList.add('garderobo-like-button-liked-state');
                            break;
                        }
                    }
                } else if (this.page._widget.favoritesProductsIds.includes(product.yml_id + ''))
                    likeButton.classList.add('garderobo-like-button-liked-state');
            }
        }

        likeButton.addEventListener('click', (event) => {
            event.stopPropagation();

            let state = true;
            if (event.target.classList.contains('garderobo-like-button-liked-state')) {
                state = false;
                event.target.classList.remove('garderobo-like-button-liked-state');
                let index = this.page._widget.favoritesProductsIds.indexOf(product.group_id + '');
                if (index !== -1) {
                    this.page._widget.favoritesProductsIds.splice(index, 1);
                }
            } else {
                event.target.classList.add('garderobo-like-button-liked-state');
                if (!this.page._widget.favoritesProductsIds.includes(product.group_id + '')) {
                    this.page._widget.favoritesProductsIds.push(product.group_id + '');
                }
            }

            let productId = product.group_id;
            if (product.offers_props_by_yml_id) {
                for (const key in product.offers_props_by_yml_id) {
                    productId = key;
                    break;

                    const value = product.offers_props_by_yml_id[key];
                    if (value && typeof value === "object") {
                        const keys = Object.keys(value);
                        if (keys.length > 0) {
                            productId = value[keys[0]]; // Получаем первое значение
                            break;
                        }
                    }
                }
            }

            self.page._widget._callbacks.addToFavorites.apply(null, [productId, state, product]);
            if (state)
                self.page._widget.analytics.sendEventAction('add_to_favorites', product.wareId, lookId, self.widgetType, self.page._widget.getPageSource(), product.price, product.old_price, self.page.response);
            else
                self.page._widget.analytics.sendEventAction('remove_from_favorites', product.wareId, lookId, self.widgetType, self.page._widget.getPageSource(), product.price, product.old_price, self.page.response);
        });
    }

    drawCustomSelectSizeButtom(selectSizeControl, product, sizeSelectStandardControl) {
        let self = this;

        selectSizeControl.addEventListener('click', function() {
            const sizesPanel = document.createElement('div');
            sizesPanel.classList.add('garderobo-set-size-panel');

            const sizePanelHeader = document.createElement('h2');
            sizePanelHeader.innerHTML = self.page.getText(Texts.SELECT_SIZE);
            sizesPanel.appendChild(sizePanelHeader);

            const sizePanelCloseButton = document.createElement('button');
            sizePanelCloseButton.classList.add('garderobo-set-size-panel-close-button');
            sizesPanel.appendChild(sizePanelCloseButton);

            const sizePanelSizeButtons = document.createElement('div');
            sizePanelSizeButtons.classList.add('garderobo-set-size-panel-size-buttons');
            sizesPanel.appendChild(sizePanelSizeButtons);

            for (let i=0; i < product.sizes.length; i++) {
                let sizeButton = document.createElement('div');
                sizeButton.innerHTML = product.sizes[i].name;
                sizeButton.setAttribute('data-id', product.sizes[i].yml_id);
                sizePanelSizeButtons.appendChild(sizeButton);
                if (selectSizeControl.innerHTML == sizeButton.innerHTML) {
                    sizeButton.classList.add('garderobo-set-size-panel-size-buttons-active');
                }

                sizeButton.addEventListener('click', function() {
                    sizeSelectStandardControl.value = this.getAttribute('data-id');
                    let event = new Event('change');
                    sizeSelectStandardControl.dispatchEvent(event);
                    sizesPanel.classList.remove('garderobo-set-size-panel-active');

                    selectSizeControl.innerHTML = this.innerHTML;
                });
            }
            document.querySelector('.garderobo-widget-popup-container').appendChild(sizesPanel);
            setTimeout(function() {
                sizesPanel.classList.add('garderobo-set-size-panel-active');
                document.querySelector('.garderobo-widget-popup').addEventListener('click', function(event) {
                    if (event.target && !event.target.classList.contains('garderobo-set-size-panel-active')) {
                        sizesPanel.classList.remove('garderobo-set-size-panel-active');
                    }
                });

            }, 100);

            sizePanelCloseButton.addEventListener('click', function() {
                sizesPanel.classList.remove('garderobo-set-size-panel-active');
            });
        });
    }


    drawCustomSelectDropdown(customDropdown, product, sizeSelectStandardControl) {
        let self = this;
        let selectedItem = document.createElement('div');
        selectedItem.classList.add('garderobo-widget-sizes-custom-dropdown-selected');

        let selectedItemText = document.createElement('div');
        selectedItemText.innerHTML = self.page.getText(Texts.SELECT_SIZE);
        selectedItemText.classList.add('garderobo-widget-sizes-custom-dropdown-selected-text');
        selectedItem.appendChild(selectedItemText);

        let dropdownIcon = document.createElement('div');
        dropdownIcon.classList.add('garderobo-widget-sizes-custom-dropdown-icon');
        selectedItem.appendChild(dropdownIcon);

        let dropdownItems = document.createElement('div');
        dropdownItems.classList.add('garderobo-widget-sizes-custom-dropdown-items', 'garderobo-widget-sizes-custom-dropdown-items-hidden');

        customDropdown.appendChild(selectedItem);
        customDropdown.appendChild(dropdownItems);

        if (product.sizes && product.sizes.length) {
            product.sizes.forEach(size => {
                let dropdownItem = document.createElement('div');
                dropdownItem.classList.add('garderobo-widget-sizes-custom-dropdown-item');
                dropdownItem.innerHTML = size.name;
                dropdownItem.setAttribute('data-id', size.yml_id);

                dropdownItems.appendChild(dropdownItem);

                dropdownItem.addEventListener('click', function() {
                    sizeSelectStandardControl.value = this.getAttribute('data-id');
                    let event = new Event('change');
                    sizeSelectStandardControl.dispatchEvent(event);

                    selectedItemText.innerHTML = this.textContent;
                    this.parentNode.classList.add('garderobo-widget-sizes-custom-dropdown-items-hidden');
                    dropdownIcon.classList.remove('garderobo-widget-sizes-custom-dropdown-icon-rotate');

                    document.querySelectorAll('.garderobo-widget-sizes-custom-dropdown-item')
                        .forEach(item => item.classList.remove('garderobo-widget-sizes-custom-dropdown-item-selected'));

                    this.classList.add('garderobo-widget-sizes-custom-dropdown-item-selected');
                })

                let allDropdownItems = document.querySelectorAll('.garderobo-widget-sizes-custom-dropdown-item');
                allDropdownItems.forEach(item => {
                    item.addEventListener('mouseenter', function() {
                        if (item.classList.contains('garderobo-widget-sizes-custom-dropdown-item-selected')) {
                            item.classList.remove('garderobo-widget-sizes-custom-dropdown-item-selected'); 
                        }
                    })
                })
            })

            if (product.sizes.length === 1) {
                selectedItemText.innerHTML = product.sizes[0].name;
            }
        }

        selectedItem.addEventListener('click', function() {
            dropdownItems.classList.remove('garderobo-widget-sizes-custom-dropdown-items-hidden');
            dropdownIcon.classList.add('garderobo-widget-sizes-custom-dropdown-icon-rotate');
        })

        let allDropdownItems = document.querySelectorAll('.garderobo-widget-sizes-custom-dropdown-item');
        allDropdownItems.forEach(item => {
            if (item.innerHTML === selectedItemText.innerHTML) {
                item.classList.add('garderobo-widget-sizes-custom-dropdown-item-selected');
            }
        })

        let gwPopup = document.querySelector('.garderobo-widget-popup-container.garderobo-widget-popup-container--opened .garderobo-widget-popup');
        gwPopup.addEventListener('click', function(e) {
            if (!customDropdown.contains(e.target)) {
                dropdownItems.classList.add('garderobo-widget-sizes-custom-dropdown-items-hidden');
                dropdownIcon.classList.remove('garderobo-widget-sizes-custom-dropdown-icon-rotate');
            }
        })
    }

    getProductSizeCategory(product) {
        if (!product.category_group)
            return '';

        let categoryGroup = product.category_group;

        if (categoryGroup.startsWith('layer'))
            return 'top';
        else if (categoryGroup.startsWith('bottom'))
            return 'bottom';
        else if (categoryGroup.startsWith('shoes'))
            return 'shoes';
        else
            return '';
    }

    drawRightItem(product, lookId, container, productId, dontRender, look, productIndex) {
        let self = this;

        const listItem = document.createElement('li');
        listItem.classList.add('garderobo-widget-popup-list-item');
        if (product.prev_picture)
            listItem.classList.add('garderobo-widget-popup-list-item-not-available');
        listItem.setAttribute('data-product-item-id', product.id);

        this.page.setProductAttributes(listItem, product, productIndex);

        if (!dontRender) {
            container.appendChild(listItem);
        }

        const picItem = document.createElement('div');
        picItem.classList.add('garderobo-widget-popup-list-item-pic');
        listItem.appendChild(picItem);

        const imgWrapper = document.createElement('div');
        imgWrapper.classList.add('garderobo-widget-popup-list-item-img-wrapper');
        picItem.appendChild(imgWrapper);

        this.getDiscountPercent(product, 'garderobo-widget-popup-list-item-discount', imgWrapper);

        let imgUrl;
        if (product.link) {
            imgUrl = document.createElement('a');

            // UTM stuff
            let link = product.link;
            let template = this.page.getWidgetParam('utm_template');
            if (template) {
                link +=
                    (link.indexOf('?') === -1 ? '?' : '&') +
                    template.replace('{block_type}', 'looks').replace('{page_type}', this.page._widget.getPageSource).replace('{yml_id}', product.yml_id);
            }

            imgUrl.href = link;

            if (self.spa) {
                imgUrl.addEventListener('click', function(event) {
                    event.preventDefault();

                    if (self.page._widget._callbacks.hasOwnProperty('linkClicked')) {
                        self.page._widget._callbacks.linkClicked.apply(null, [link]);
                    }
                })
            }

            imgUrl.addEventListener('click', () => {
                //this.ecommerce.productClickPopup(product, look);
                let wareId = product.wareId ? product.wareId : product.id;
                self.page._widget.analytics.sendEventWidgetClick(wareId, self.widgetType, self.page._widget.getPageSource(), lookId, product.price, product.old_price, self.page.response);
            });
            imgWrapper.appendChild(imgUrl);
        }

        const imgItem = document.createElement('img');
        imgItem.classList.add('garderobo-widget-popup-list-item-img');
        imgItem.src = product.picture;
        if (imgUrl) {
            imgUrl.appendChild(imgItem);
        } else {
            imgWrapper.appendChild(imgItem);
        }

        const swapItem = document.createElement('span');
        swapItem.classList.add('garderobo-widget-popup-list-item-swap');
        swapItem.innerHTML = this.page.getText(Texts.CHANGE);

        const swapItemDiv = document.createElement('div');
        swapItemDiv.classList.add('garderobo-widget-popup-list-item-swap-element');

        const swapItemIcon = document.createElement('div');
        swapItemIcon.classList.add('garderobo-widget-popup-list-item-swap-icon');

        const swapItemText = document.createElement('div');
        swapItemText.classList.add('garderobo-widget-popup-list-item-swap-text');
        swapItemText.innerHTML = this.page.getText(Texts.CHANGE);

        swapItemDiv.appendChild(swapItemIcon);
        swapItemDiv.appendChild(swapItemText);

        swapItem.addEventListener('click', () => this.swap(product, listItem, product.item_id, lookId, product.prev_picture?true:false));
        picItem.appendChild(swapItem);

        if (this.page.getWidgetParam('new_change_btn')) {
            swapItemDiv.addEventListener('click', () => this.swap(product, listItem, product.item_id, lookId, product.prev_picture?true:false));
            picItem.appendChild(swapItemDiv);
        }

        if (product.prev_picture)
            swapItem.click();

        
        if (product.prev_picture && this.page.getWidgetParam('new_change_btn'))
            swapItemDiv.click();
        // todo - outfit-swap-container

        const textItem = document.createElement('div');
        textItem.classList.add('garderobo-widget-popup-list-item-text');
        listItem.appendChild(textItem);

        const likeButton = document.createElement('div');
        likeButton.classList.add('garderobo-widget-popup-list-item-like-button');
        textItem.appendChild(likeButton);
        this.setLikedState(product, likeButton, lookId);

        const brandItem = document.createElement('p');
        brandItem.classList.add('garderobo-widget-popup-list-item-text-brand');
        brandItem.innerHTML = product.brand;
        textItem.appendChild(brandItem);

        const titleItem = document.createElement('h3');
        titleItem.classList.add('garderobo-widget-popup-list-item-text-title');

        if (self.page.type == 'platform')
            titleItem.innerHTML = product.name.replace(product.brand, '').trim();
        else
            titleItem.innerHTML = product.name;
        textItem.appendChild(titleItem);

        const bottomItems = document.createElement('div');
        bottomItems.classList.add('garderobo-widget-popup-list-item-text-bottom');
        textItem.appendChild(bottomItems);

        const bottomLeft = document.createElement('div');
        bottomItems.appendChild(bottomLeft);

        const pricesItem = document.createElement('div');
        pricesItem.classList.add('garderobo-widget-popup-list-item-text-prices');

        if (this.page.getWidgetParam('use_cardprice') && this.page._widget.user_id && product.offers_props_by_yml_id) {
            const keys = Object.keys(product.offers_props_by_yml_id);
            if (keys.length) {
                const priceItem = document.createElement('div');
                priceItem.classList.add('garderobo-widget-popup-list-item-text-price');
                priceItem.innerHTML = this.page.priceFormatter(product.offers_props_by_yml_id[keys[0]][this.page.getWidgetParam('use_cardprice')]);
                pricesItem.appendChild(priceItem);
            }
        } else if (product.price && !product.old_price) {
            const priceItem = document.createElement('div');
            priceItem.classList.add('garderobo-widget-popup-list-item-text-price');
            priceItem.innerHTML = this.page.priceFormatter(product.price);
            pricesItem.appendChild(priceItem);
        } else if (product.price && product.old_price) {
            const discountItem = document.createElement('div');
            discountItem.classList.add('garderobo-widget-popup-list-item-text-discount');
            discountItem.innerHTML = this.page.priceFormatter(product.old_price);

            if (product.price != product.old_price) {
                pricesItem.appendChild(discountItem);
            }

            const priceNewItem = document.createElement('div');
            priceNewItem.classList.add('garderobo-widget-popup-list-item-text-new-price');
            priceNewItem.innerHTML = this.page.priceFormatter(product.price);
            pricesItem.appendChild(priceNewItem);
        }
        bottomLeft.appendChild(pricesItem);

        if (product.params && product.params.promotion_id) {
            let productPromoText = document.createElement('div');
            productPromoText.classList.add('garderobo-widget-product-promo');
            productPromoText.innerHTML = product.params.promotion_id;
            bottomLeft.appendChild(productPromoText);
        }

        const isLookWithSizes = look.block_with_sizes;

        const actionControls = document.createElement('div');
        actionControls.classList.add('garderobo-widget-popup-actions-controls');

        const actionButtons = document.createElement('div');
        actionButtons.classList.add('garderobo-widget-popup-action-buttons');

        const defaultSelectValue = this.page.getText(Texts.SELECT_SIZE);

        const cartBtn = document.createElement(isLookWithSizes ? 'button' : 'a');
        cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn');
        cartBtn.innerHTML = this.page.getText(Texts.TO_CART);
        if (product && product.group_id)
            cartBtn.setAttribute('data-product-id', product.group_id);
        actionButtons.appendChild(cartBtn);

        if (isLookWithSizes) {
            const sizesButtons = document.createElement('div');
            sizesButtons.style.display = 'none';
            sizesButtons.classList.add('garderobo-widget-sizes-buttons');

            const sizesSelect = document.createElement('select');
            sizesSelect.classList.add('garderobo-widget-sizes');
            const sizesOptionPlaceholder = document.createElement('option');
            sizesOptionPlaceholder.innerHTML = defaultSelectValue;
            sizesOptionPlaceholder.value = '';
            sizesSelect.appendChild(sizesOptionPlaceholder);

            sizesSelect.addEventListener('change', (event) => {
                const value = event.target.value;
                if (!value.trim()) {
                    cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
                    cartBtn.removeAttribute('data-offer-id');
                    self.updateTotalInfo(look);
                    return;
                } else
                    cartBtn.setAttribute('data-offer-id', event.target.value);
                const btn = cartBtn;

                const errBox = listItem.querySelector('.garderobo-widget-popup-list-item-text-error');
                if (btn) {
                    if (value === defaultSelectValue) {
                        btn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
                    } else {
                        btn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn--disabled');

                        const allCartPanel = document.querySelector('.garderobo-widget-look__bottom-panel');
                        if (allCartPanel) {
                            allCartPanel.classList.remove('garderobo-widget-look__bottom-panel-disabled');
                            let allCartBtn = allCartPanel.querySelector('.garderobo-widget-popup-list-item-text-cart-btn');
                            if (allCartBtn)
                                allCartBtn.innerHTML = self.page.getText(Texts.TO_CART);
                        }

                        if (errBox) {
                            errBox.innerHTML = null;

                            let customSelectSize = listItem.querySelector('.garderobo-widget-sizes-custom-dropdown');
                            if (customSelectSize) {
                                customSelectSize.classList.remove('garderobo-widget-sizes-custom-dropdown-error');                                
                            }
                        }
                            
                        let sizeValue = null;
                        for (let j = 0; j < product.sizes.length; j++) {
                            if (product.sizes[j].yml_id === value) {
                                sizeValue = product.sizes[j].name;

                                if (self.page._widget.cartProductsIds) {
                                    if (self.page._widget.cartProductsIds.includes(product.sizes[j].yml_id + '')) {
                                        let btnAlreadyInCartCaption = self.page.getText(Texts.OPEN_CART);
                                        btn.classList.add('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                                        if (btn.innerHTML != btnAlreadyInCartCaption)
                                            btn.setAttribute('data-text', btn.innerHTML);
                                        btn.innerHTML = btnAlreadyInCartCaption;
                                        if (self.page._widget.cartLink)
                                            btn.setAttribute('data-link', self.page._widget.cartLink);
                                    } else {
                                        if (btn.getAttribute('data-text'))
                                            btn.innerHTML = btn.getAttribute('data-text');
                                        btn.removeAttribute('data-link');
                                        btn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn-link-state');
                                    }
                                }
                                break;
                            }
                        }
                        self.updateTotalInfo(look);
                        //this.ecommerce.sizeChangePopup(product, sizeValue);
                    }
                }
            });

            if (product.sizes && product.sizes.length) {
                let isFindedSelectedSize = false;
                let productSizeCategory = self.getProductSizeCategory(product);
                for (let j = 0; j < product.sizes.length; j++) {
                    if (product.sizes[j].is_available) {
                        let sizeOption = document.createElement('option');
                        sizeOption.value = product.sizes[j].yml_id;
                        sizeOption.innerHTML = product.sizes[j].name;
                        sizesSelect.appendChild(sizeOption);

                        let sizeButton = document.createElement('div');
                        sizeButton.setAttribute('data-id', product.sizes[j].yml_id);
                        sizeButton.innerHTML = product.sizes[j].name;
                        sizesButtons.appendChild(sizeButton);

                        sizeButton.addEventListener('click', (event) => {
                            event.target.classList.add('garderobo-widget-sizes-button-active');
                            var childButtons = sizesButtons.children;
                            for (var i = 0; i < childButtons.length; i++) {
                                if (childButtons[i] !== event.target) {
                                    childButtons[i].classList.remove('garderobo-widget-sizes-button-active');
                                }
                            }
                            sizesSelect.value = event.target.getAttribute('data-id');

                            let changeEvent = new Event('change');
                            sizesSelect.dispatchEvent(changeEvent);
                        });

                        if (self.page.platform && self.page.platform.folderData && !isFindedSelectedSize && productSizeCategory) {
                            if (self.page.platform.folderData['sizes_' + productSizeCategory].includes(product.sizes[j].name)) {
                                isFindedSelectedSize = true;
                                let clickEvent = new Event('click');
                                sizeButton.dispatchEvent(clickEvent);
                            }
                        }

                        if (product.sizes.length == 1) {
                            if (!this.page.getWidgetParam('add_all_items_to_cart_panel')) {
                                let clickEvent = new Event('click');
                                sizeButton.dispatchEvent(clickEvent);
                            }
                        }
                    }
                }
            } else {
                let sizeOption = document.createElement('option');
                sizeOption.value = product.yml_id;
                if (product.sizes)
                    product.sizes.push({name: Texts.ONE_SIZE, is_available: true, yml_id: product.yml_id});
                sizeOption.innerHTML = this.page.getText(Texts.ONE_SIZE);
                sizesSelect.appendChild(sizeOption);
            }

            if (self.sizeYmlId) {
                let sizeOptions = sizesSelect.options;

                for (let i = 0; i < sizeOptions.length; i++) {
                    if (sizeOptions[i].value === self.sizeYmlId) {
                        sizesSelect.value = self.sizeYmlId;
                        break;
                    }
                }
            }

            actionControls.appendChild(sizesSelect);
            actionControls.appendChild(sizesButtons);

            if (this.page.getWidgetParam('custom_select_size_popup')) {
                sizesSelect.style.display = 'None';

                const sizesSelectButton = document.createElement('div');
                sizesSelectButton.classList.add('garderobo-widget-sizes-custom-button');
                sizesSelectButton.innerHTML = defaultSelectValue;

                actionControls.appendChild(sizesSelectButton);
                self.drawCustomSelectSizeButtom(sizesSelectButton, product, sizesSelect);
            }

             if (this.page.getWidgetParam('custom_select_dropdown')) {
                sizesSelect.style.display = 'none';

                const customSelectDropdown = document.createElement('div');
                customSelectDropdown.classList.add('garderobo-widget-sizes-custom-dropdown');

                actionControls.appendChild(customSelectDropdown);
                self.drawCustomSelectDropdown(customSelectDropdown, product, sizesSelect);
            }
            
        }

        if (this.page.getWidgetParam('like_dislike_enabled')) {
            const likeBtn = document.createElement('button');
            likeBtn.classList.add('garderobo-widget-popup-list-item-text-like-btn');
            likeBtn.type = 'button';
            actionButtons.appendChild(likeBtn);

            likeBtn.addEventListener('click', (e) => {
                this.page._widget._callbacks.addToFavorites.apply(null, [product]);
                this.page._widget.analytics.sendEventAction('add_to_favorites', product.wareId, lookId, this.widgetType, this.page._widget.getPageSource(), product.price, product.old_price, this.page.response);
            });
        }

        if (!isLookWithSizes) {
            cartBtn.href = product.link;
            cartBtn.setAttribute('target', '_blank');
        } else {
            if ((product.sizes.length > 1) || this.page.getWidgetParam('add_all_items_to_cart_panel'))
                cartBtn.classList.add('garderobo-widget-popup-list-item-text-cart-btn--disabled');
            cartBtn.type = 'button';
            cartBtn.addEventListener('click', (e) => {
                if (cartBtn.getAttribute('data-link')) {
                    document.location.href = cartBtn.getAttribute('data-link');
                    return;
                }

                const selectNode = cartBtn.closest('.garderobo-widget-popup-list-item').querySelector('.garderobo-widget-sizes');
                let offersPropsByYmlId;

                if (product.offers_props_by_yml_id && selectNode.value && self.page.getWidgetParam('skip_offer_props') != true) {
                    const key = Object.keys(product.offers_props_by_yml_id[selectNode.value])[0];
                    //FIX FOR KENG
                    if ((key != 'product_id') && (key != 'cardprice'))
                        offersPropsByYmlId = product.offers_props_by_yml_id[selectNode.value][key];
                    else
                        offersPropsByYmlId = selectNode.value;
                } else if (selectNode && selectNode.value) {
                    offersPropsByYmlId = selectNode.value;
                }

                let product_id;
                if (product.wareId)
                    product_id = product.wareId;
                else
                    product_id = product.id;

                const errBox = cartBtn.closest('.garderobo-widget-popup-list-item').querySelector('.garderobo-widget-popup-list-item-text-error');
                if (!selectNode || !selectNode.value || selectNode.value === defaultSelectValue) {
                    errBox.innerHTML = this.page.getText(Texts.PLEASE_SELECT_SIZE);
                    let customeSelectSize = cartBtn.closest('.garderobo-widget-popup-list-item').querySelector('.garderobo-widget-sizes-custom-dropdown');
                    if (customeSelectSize) {
                        customeSelectSize.classList.add('garderobo-widget-sizes-custom-dropdown-error');
                    }
                } else if (this.page._widget._callbacks.hasOwnProperty('addToCart')) {
                    this.page._widget.analytics.sendEventAddToCartFromPopup(product_id, this.widgetType, this.page._widget.getPageSource(), product.price, product.old_price, look.look_id, this.page.response);
                    //this.ecommerce.addToCartClickPopup(product, look, selectNode && selectNode.value);
                    this.page._widget._callbacks.addToCart.apply(null, [product.id, offersPropsByYmlId]);
                    errBox.innerHTML = null;
                    let customeSelectSize = cartBtn.closest('.garderobo-widget-popup-list-item').querySelector('.garderobo-widget-sizes-custom-dropdown');
                    if (customeSelectSize) {
                        customeSelectSize.classList.remove('garderobo-widget-sizes-custom-dropdown-error');
                    }
                } else if (this.page._widget._callbacks.hasOwnProperty('addToCartEvent')) {
                    this.page._widget.analytics.sendEventAddToCartFromPopup(product_id, this.widgetType, this.page._widget.getPageSource(), product.price, product.old_price, look.look_id, this.page.response);
                    //this.ecommerce.addToCartClickPopup(product, look, selectNode && selectNode.value);
                    this.page._widget._callbacks.addToCartEvent.apply(null, [offersPropsByYmlId, product]);

                    if (self.page._widget.cartProductsIds) {
                        //self.page._widget.cartProductsIds.push(offersPropsByYmlId + '');
                        let sizesSelect = cartBtn.parentNode.parentNode.querySelector('.garderobo-widget-sizes');
                        if (sizesSelect)
                            sizesSelect.dispatchEvent(new Event('change'));
                    }
                }
            });
        }

        if (self.sizeYmlId) {
            cartBtn.classList.remove('garderobo-widget-popup-list-item-text-cart-btn--disabled');
        }

        const swapButton = document.createElement('button');
        swapButton.classList.add('garderobo-widget-popup-list-item-swap-button');
        swapButton.innerText = self.page.getText(Texts.SIMILAR_ITEMS);
        swapButton.style.display = 'none';
        swapButton.addEventListener('click', () => this.swap(product, listItem, product.item_id, lookId));
        actionButtons.appendChild(swapButton);

        actionControls.appendChild(actionButtons);
        bottomItems.after(actionControls);

        const errContainer = document.createElement('div');
        errContainer.classList.add('garderobo-widget-popup-list-item-text-error');
        actionControls.after(errContainer);

        if (this.similars[productId]) {
            this.swapDraw(product, listItem, this.similars[productId], lookId, true);
        }

        return listItem;
    }

    onSlide(c, dir) {
        this.sliderPos += dir;
        if (this.sliderPos < 0) {
            this.sliderPos = this.items.length - 1;
        } else if (this.sliderPos >= this.items.length) {
            this.sliderPos = 0;
        }
        this.drawRightItems(this.items[this.sliderPos]);

        //this.ecommerce.slidePopup(this.items[this.sliderPos], dir);
    }

    swap(product, listItem, lookItemId, lookId, isAutoSwap) {
        this.sizeYmlId = null;
        let alreadyLoaded = this.similars[product.id];

        if (alreadyLoaded) {
            const loadedProduct = alreadyLoaded.find((_product) => _product.id === product.id || _product.id === product.wareId);

            this.swapDraw(loadedProduct, listItem, this.similars[product.id], lookId);
        } else {
            this.page
                .fetchSimilars(lookItemId, product.wareId, { useCrop: 1 })
                .then((data) => {
                    if (data.products) {
                        const allProducts = [...data.products].map((_product) => {
                            _product['category-name'] = product['category-name'];
                            return _product;
                        });

                        // перемещаем начальный продукт в начало массива
                        const currentIndex = allProducts.findIndex((_product) => _product.id === product.wareId);
                        if (currentIndex !== 0 && currentIndex !== -1) {
                            const currentProduct = allProducts.splice(currentIndex, 1).pop();
                            allProducts.unshift(currentProduct);
                        }
                        this.similars[lookItemId] = allProducts;

                        for (let i = 0; i < allProducts.length; i++) {
                            let allProductsOther = [...allProducts];
                            let currentOtherProduct = allProductsOther[i];

                            // перемещаем продукт в начало массива
                            let currentIndex = allProductsOther.findIndex((_product) => _product.id === currentOtherProduct.id);
                            if (currentIndex !== 0 && currentIndex !== -1) {
                                const currentProduct = allProductsOther.splice(currentIndex, 1).pop();
                                allProductsOther.unshift(currentProduct);
                            }

                            this.similars[currentOtherProduct.id] = allProductsOther;
                        }
                        this.swapDraw(product, listItem, allProducts, lookId);
                        listItem.classList.remove('garderobo-widget-popup-list-item-not-available');
                    }
                })
                .catch(() => console.log('error'));
        }
    }

    getDiscountPercent(item, className, contaainer) {
        if ((item.old_price) && (item.old_price != item.price)) {
            let originalPrice = item.old_price;
            let discountedPrice = item.price;

            let discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
            discountPercentage = Math.round(Math.round(discountPercentage * 100) / 100);

            let discountContainer = document.createElement('div');
            discountContainer.innerHTML = discountPercentage + '%';
            discountContainer.classList.add(className);
            discountContainer.style.display = 'none';

            contaainer.appendChild(discountContainer);
        }
    }

    swapItemDraw(item, product, lookId) {
        let swapLi = document.createElement('li');
        swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item');

        if (item.id === product.wareId || item.id === product.id) {
            swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item--selected');

            let swapCloseBtn = document.createElement('button');
            swapCloseBtn.setAttribute('type', 'button');
            swapCloseBtn.classList.add('garderobo-widget-popup__btn-close');
            swapLi.appendChild(swapCloseBtn);
        }
        swapLi.setAttribute('data-similar-id', item.id);

        if (lookId)
            swapLi.addEventListener('click', () => this.replaceProduct(product, item, lookId)); // todo - remove listener
        else {
            swapLi.classList.add('garderobo-widget-popup-list-item-swap-container-item--not-available');
        }

        let liContent = document.createElement('div');
        liContent.classList.add('garderobo-widget-popup-list-item-swap-item-content');
        if (item.id === product.id) {
            liContent.classList.add('garderobo-widget-popup-list-item-swap-item-content--selected');
        }

        if (lookId) {
            const likeButton = document.createElement('div');
            likeButton.classList.add('garderobo-widget-popup-list-swap-item-like-button');
            liContent.appendChild(likeButton);
            this.setLikedState(item, likeButton, lookId);
        }

        swapLi.appendChild(liContent);

        let imgWrapper = document.createElement('div');
        imgWrapper.classList.add('garderobo-widget-popup-list-item-swap-item-img-wrapper');
        liContent.appendChild(imgWrapper);

        let img = document.createElement('img');
        img.classList.add('garderobo-widget-popup-list-item-swap-item-img');
        img.src = item.picture;
        imgWrapper.appendChild(img);

        this.getDiscountPercent(item, 'garderobo-widget-popup-list-item-swap-item-discount', imgWrapper);

        const nameProductPopup = document.createElement('p');
        nameProductPopup.classList.add('garderobo-widget-popup-list-item-name');
        nameProductPopup.style.display = 'none';
        if (item.name)
            nameProductPopup.innerHTML = item.name;

        const brandProductPopup = document.createElement('p');
        brandProductPopup.classList.add('garderobo-widget-popup-list-item-brand');
        brandProductPopup.style.display = 'none';
        brandProductPopup.innerHTML = item.brand;

        let oldPrice = '';

        let price = document.createElement('div');
        price.classList.add('garderobo-widget-popup-list-item-swap-item-price');
        if (item.price) {
            if ((item.old_price) && (item.price != item.old_price)) {
                price.classList.add('garderobo-widget-popup-list-item-swap-item-price--sale');

                oldPrice = document.createElement('span');
                oldPrice.classList.add('garderobo-widget-popup-list-item-swap-item-price--old');
                oldPrice.innerHTML = this.page.priceFormatter(item.old_price);
                oldPrice.style.display = 'none';
            }
            price.innerHTML = this.page.priceFormatter(item.price);
            if (oldPrice)
                price.appendChild(oldPrice);
        }

        liContent.appendChild(nameProductPopup);
        liContent.appendChild(brandProductPopup);
        liContent.appendChild(price);

        if (item.sizes) {
            let sizeButtonsWrapper = document.createElement('div');
            sizeButtonsWrapper.classList.add('garderobo-widget-popup-list-item-swap-container-item-sizes-wrapper');
            sizeButtonsWrapper.style.display = 'none';

            let sizeButtons = document.createElement('div');
            sizeButtons.classList.add('garderobo-widget-popup-list-item-swap-container-item-sizes');

            for (let j = 0; j < item.sizes.length; j++) {
                let sizeButton = document.createElement('div');
                sizeButton.innerText = item.sizes[j].name;
                sizeButton.setAttribute('data-yml-id', item.sizes[j].yml_id);
                sizeButton.classList.add('garderobo-widget-popup-list-item-swap-container-product-size');
                sizeButtons.appendChild(sizeButton);
            }

            let allProductSizes = document.querySelectorAll('.garderobo-widget-popup-list-item-swap-container-product-size');
            allProductSizes.forEach(size => {
                size.addEventListener('click', () => {
                    this.sizeYmlId = size.getAttribute('data-yml-id');
                })
            })

            sizeButtonsWrapper.appendChild(sizeButtons);
            swapLi.appendChild(sizeButtonsWrapper);
        }

        return swapLi;
    }

    swapDraw(product, listItem, products, lookId, isHidden) {
        const swapContainer = document.createElement('div');
        swapContainer.classList.add('garderobo-widget-popup-list-item-swap-container');
        if (isHidden) {
            swapContainer.classList.add('garderobo-widget-popup-list-item-swap-container--hidden');
        }
        listItem.appendChild(swapContainer);

        const swapUl = document.createElement('ul');
        swapUl.classList.add('garderobo-widget-popup-list-item-swap-container-list');
        swapContainer.appendChild(swapUl);

        if (product.source_product && product.source_product.wareId) {
            swapUl.appendChild(this.swapItemDraw(product.source_product, product));
        }

        for (let i = 0; i < products.length; i++) {
            swapUl.appendChild(this.swapItemDraw(products[i], product, lookId));
        }
    }

    replaceProduct(product, replacer, lookId) {
        replacer.wareId = replacer.id;

        let currentLook;

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].look_id === lookId) {
                currentLook = this.items[i];
                break;
            }
        }

        const targetGrid = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] .garderobo-grid-look div[data-product-id="${product['id']}"]`);
        if (targetGrid) {
            targetGrid.firstChild.src = replacer.picture;
            targetGrid.setAttribute('data-product-id', replacer.id);
            targetGrid.classList.remove('garderobo-grid-look__product-disabled-layout');
        }

        const targetInPlatform = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] .garderobo-grid-look__platform-product[data-product-id="${product['id']}"]`);
        if (targetInPlatform) {
            targetInPlatform.firstChild.src = replacer.picture.replace('/medium/', '/crop/').replace('.jpg', '.png');
            targetInPlatform.setAttribute('data-product-id', replacer.id);
            targetInPlatform.classList.remove('garderobo-grid-look__product-disabled-layout');
        }

        const targetGrid2 = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] .garderobo-grid-look-2 div[data-product-id="${product['id']}"]`);
        if (targetGrid2) {
            targetGrid2.firstChild.style.display = 'none';
            const layerType = this.page.getLayerType(replacer['category-name']);
            targetGrid2.firstChild.src = replacer.picture;

            targetGrid2.classList.remove('garderobo-grid-look__product-disabled-layout');

            if (!targetGrid2.classList.contains('garderobo-grid-look__product_img_with_positions')) {
                targetGrid2.style.gridRow = this.page.getUpdatedGridSize(replacer, targetGrid2.getAttribute('data-grid-row'), layerType, product.is_main_product, 'y', targetGrid2.getAttribute('data-template'));
                targetGrid2.style.gridColumn = this.page.getUpdatedGridSize(replacer, targetGrid2.getAttribute('data-grid-column'), layerType, product.is_main_product, 'x', targetGrid2.getAttribute('data-template'));
            }
            targetGrid2.firstChild.style.display = 'block';
            targetGrid2.setAttribute('data-product-id', replacer.id);

            const targetCaption = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] .garderobo-grid-look-2 p[data-product-id="${product['id']}"]`);
            if (targetCaption) {
                targetCaption.innerHTML = '<strong>' + replacer.brand.toUpperCase() + '</strong>' + replacer.price;
                targetCaption.setAttribute('data-product-id', replacer.id);
            }
        }

        const targetCard = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] .garderobo-widget-look-container-cards__item[data-product-id="${product['id']}"]`);
        if (targetCard) {
            targetCard.style.backgroundImage = `url('${replacer.picture}')`;
            targetCard.setAttribute('data-product-id', replacer.id);
        }

        const target = document.querySelector(`.garderobo-widget-popup-content [data-look="${lookId}"] [data-category-collage="${product['category-name']}"]`);
        if (target && !targetGrid && !targetGrid2) {
            target.style['background-image'] = `url(${replacer.picture})`;
        }

        const targetGridV3 = document.querySelectorAll(`.garderobo-widget-popup-content .garderobo-widget-feed-item[data-look="${lookId}"] .garderobo-grid-look__product_grid_v_3[data-product-id="${product['id']}"]`);
        if (targetGridV3) {
            targetGridV3.forEach(function (element) {
                element.style['background-image'] = `url(${replacer.picture})`;
                element.setAttribute('data-product-id', replacer.id);
                if (replacer.waist_length && element.getAttribute('data-waist')) {
                    element.setAttribute('data-waist', product.waist_length);

                    let koeff = replacer.crop_wh[0] / replacer.waist_length;
                    let templateLeft = parseInt(element.getAttribute('data-left'));
                    let templateWidth = parseInt(element.getAttribute('data-width'));

                    let productWidth = templateWidth * koeff;
                    let productLeft = templateLeft + templateWidth/2 - productWidth/2;

                    element.style.width = productWidth + '%';
                    element.style.left = productLeft + '%';

                    if ((productLeft + productWidth) > 100) {
                        element.style.left = 'unset';
                        element.style.right = 0;
                    }

                    if (productLeft < 0)
                        element.style.left = 0;
                }
            });
        }

        const swapContainer = document.querySelector(`[data-product-item-id="${product.id}"] .garderobo-widget-popup-list-item-swap-container`);

        const oldProductItem = swapContainer.parentNode;
        if (oldProductItem) {
            const productIndex = Array.prototype.indexOf.call(oldProductItem.parentNode.children, oldProductItem);
            const container = document.querySelector('.garderobo-widget-popup-list-content');
            const newItem = this.drawRightItem(replacer, lookId, container, product.id, true, currentLook, productIndex);
            oldProductItem.replaceWith(newItem);

            const newItemRendered = document.querySelector(`[data-product-item-id="${replacer.id}"]`); // todo - needed?
            newItemRendered.appendChild(swapContainer); // tood - needed?
        }
        swapContainer.remove();

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].look_id === lookId) {
                this.updateTotalInfo(this.items[i]);
                for (let j = 0; j < this.items[i].products.length; j++) {
                    if (this.items[i].products[j].id === product.id) {
                        this.items[i].products[j] = replacer;
                    }
                }
            }
        }

        //this.ecommerce.replaceProductPopup({ ...currentLook, products: [replacer] }, product, replacer);
    }
}
